<script>
export default {
    props: {
      empresa: { type: Object, required: true },
      oldHistoricoPadrao: { type: Object, required: true },
    },
  data() {
    return {
      titleBody: 'Alterar Cidade',
      historicoPadrao: {
        id: -1,
        descricao: null,
        status: true
      },
    }
  },
  created() {},
  mounted() {
    this.$emit('newTitle', this.titleBody);
    this.historicoPadrao = this.oldHistoricoPadrao;
  },
  methods: {
    setPut() {
      this.$emit('doPut', this.historicoPadrao);
    },
  },
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <form id="form-centro-resultado" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="codigo_id" class="col-form-label">Código</label>
                <div class="col">
                  <input disabled v-model="historicoPadrao.id" class="form-control text-right" type="text" placeholder="Codigo Cidade" id="centro_resultado_id">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-10">
              <div class="form-group">
                <label for="nome_cidade" class="col-form-label">Nome da Cidade</label>
                  <div class="col">
                    <input v-model="historicoPadrao.descricao" class="form-control" type="text" placeholder="Cidade" id="centro_resultado_descricao">
                  </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-10">
              <div class="form-group">
                <label for="estado" class="col-form-label">Estado</label>
                <div class="col">
                  <input type="text" class="form-control" placeholder="digite o estado">
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPost">Gravar</button>
    </div>
  </div>
</div>
</template>